
import * as vercelRuntime$0gmtn7RVju from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_@upstash+redis@1.34.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.28.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [
    "amazonaws.com",
    "s3.eu-central-1.amazonaws.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "avif",
    "webp",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$0gmtn7RVju, defaults: {} }
}
        