import payload_plugin_TmGCCgLFtI from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_PYeoRXxw7j from "/vercel/path0/node_modules/.pnpm/@pinia+colada-nuxt@0.0.3_magicast@0.3.5_rollup@4.28.0/node_modules/@pinia/colada-nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_o8Do8mJcUw from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Wp6dTWL4y9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JI7lciI0TF from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_FYqtCwpj1C from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Z1r6A59JDh from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UW3RdWNokc from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZerOw0wTLK from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/apps/abmeldung-digital/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_SVkGKDfDRS from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/abmeldung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_jdkN3kvF5O from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.9.0_@upstash+redis@1.34.3_eslint@9.13.0_j_rvohdm5e4gos7aazf5eh3xkn5y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Qw90IDulMx from "/vercel/path0/node_modules/.pnpm/@pinia+colada-nuxt@0.0.3_magicast@0.3.5_rollup@4.28.0/node_modules/@pinia/colada-nuxt/dist/runtime/plugin.js";
import slideovers_1Yl6Fq9Moj from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.19.2_async-validator@4.2.5_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast_udp6pnwhe5fggzbclkl42e5ora/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_4yFEdEhsRa from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.19.2_async-validator@4.2.5_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast_udp6pnwhe5fggzbclkl42e5ora/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_G1PybLjtX4 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.19.2_async-validator@4.2.5_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.2_magicast_udp6pnwhe5fggzbclkl42e5ora/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_Mi73Otjtp5 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.28.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_sGfFIoZKfm from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.9.1_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@20.9.0_sass-embedded@1._huintbe56uiwbricgipiicdixm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_FnYDiacxbV from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescri_wxeqa66yjn6fpgdb4n5ejatooe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import event_directive_YCbI7d1p4o from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_JCMvpbgZ2W from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import feathers_r9RHv5VuHz from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_ZWaWEo2Woe from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_EGbfpheBQT from "/vercel/path0/layers/base/plugins/trpc.ts";
import wartungsarbeiten_client_wVkIgb3gzE from "/vercel/path0/layers/base/plugins/wartungsarbeiten.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/abmeldung-digital/plugins/sentry.client.ts";
export default [
  payload_plugin_TmGCCgLFtI,
  payload_plugin_PYeoRXxw7j,
  revive_payload_client_o8Do8mJcUw,
  unhead_Wp6dTWL4y9,
  router_JI7lciI0TF,
  payload_client_FYqtCwpj1C,
  navigation_repaint_client_Z1r6A59JDh,
  check_outdated_build_client_UW3RdWNokc,
  chunk_reload_client_ZerOw0wTLK,
  plugin_zbacBAXhj0,
  plugin_vue3_SVkGKDfDRS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jdkN3kvF5O,
  plugin_Qw90IDulMx,
  slideovers_1Yl6Fq9Moj,
  modals_4yFEdEhsRa,
  colors_G1PybLjtX4,
  plugin_client_Mi73Otjtp5,
  plugin_sGfFIoZKfm,
  plugin_FnYDiacxbV,
  event_directive_YCbI7d1p4o,
  script_JCMvpbgZ2W,
  feathers_r9RHv5VuHz,
  cookie_banner_client_ZWaWEo2Woe,
  trpc_EGbfpheBQT,
  wartungsarbeiten_client_wVkIgb3gzE,
  sentry_client_shVUlIjFLk
]